@import "breakpoint";
@import "settings";
@import "foundation/components/global"; // *always required
@import "foundation/components/grid";

.paragraphs-item-carousel {
//  padding: 30px 20px;
}

.entity-carousels {
  position: relative;

  .ds-image:hover {
    text-decoration: none;
  }

  .ds-title {
    @include breakpoint($desktop) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    background: grey;
    text-align: left;
    color: white;
    z-index: 2;
    font-weight: 400;
    padding: 20px 40px;

    h2 {
      font-family: $font-main;
      font-size: 20px;
      @include breakpoint($desktop) {
        font-size: 53px;
        line-height: 1.1em;
      }
      text-transform: none;
    }
    .field-name-field-call-to-action {
      font-size: 14px;
      line-height: 1.2em;
      @include breakpoint($desktop) {
        line-height: 1.3em;
        font-size: 18px;
      }
      &:after {
        content: ">";
        padding-left: 10px;
      }
    }

    @include breakpoint($desktop) {
      font-size: 2em;
    }
  }
}

.owl-video-wrapper {
  height: 0;
  //padding-bottom: 56.25%;
  padding-bottom: 46.83%;
  position: relative;
}
.owl-video, .owl-video-tn, .paragraphs-item-carousel .owl-carousel .owl-video-frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.paragraphs-item-carousel .owl-carousel .owl-video-frame iframe {
  height: 100%;
}
